import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, Pagination } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  detailContainer: {
    padding: '20px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    borderRadius: '8px',
    marginBottom: '20px',
    backgroundColor: '#ffffff',
    border: '2px solid #e0e0e0',
  },
  emailHeader: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333333',
  },
  emailDetails: {
    marginBottom: '20px',
  },
  backToTable: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    color: '#007bff',
    cursor: 'pointer',
  },
});

const Mail = () => {
  const classes = useStyles();

  // Dummy data for demonstration
  const [data, setData] = useState([
    { id: 1, sender: 'John Doe', recipient: 'Jane Doe', receivedTime: '2022-04-01 10:30 AM', subject: 'Meeting Agenda', content: 'Sample content for Meeting Agenda' },
    { id: 2, sender: 'Alice Smith', recipient: 'Bob Johnson', receivedTime: '2022-04-02 02:45 PM', subject: 'Project Update', content: 'Sample content for Project Update' },
    { id: 3, sender: 'Eva Brown', recipient: 'David White', receivedTime: '2022-04-03 08:15 AM', subject: 'Task Assignments', content: 'Sample content for Task Assignments' },
    // Add more records
    { id: 4, sender: 'Michael Jordan', recipient: 'LeBron James', receivedTime: '2022-04-04 11:30 AM', subject: 'Basketball Strategy', content: 'Sample content for Basketball Strategy' },
    { id: 5, sender: 'Serena Williams', recipient: 'Roger Federer', receivedTime: '2022-04-05 03:45 PM', subject: 'Tennis Tournament', content: 'Sample content for Tennis Tournament' },
    { id: 6, sender: 'Usain Bolt', recipient: 'Simone Biles', receivedTime: '2022-04-06 09:15 AM', subject: 'Olympic Preparation', content: 'Sample content for Olympic Preparation' },
    // Add more records...
  ]);

  // State to track selected row
  const [selectedRow, setSelectedRow] = useState(null);
  // State for pagination
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;

  // Function to handle click on a row
  const handleClickRow = (row) => {
    setSelectedRow(row);
  };

  // Function to handle back to table click
  const handleBackToTable = () => {
    setSelectedRow(null);
  };

  // Function to handle page change
  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const paginatedData = data.slice(startIndex, endIndex);

  return (
    <div>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div">
            Mail
          </Typography>
        </Toolbar>
      </AppBar>
      <Container style={{ marginTop: '20px' }}>
        {!selectedRow ? (
          <>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow style={{ backgroundColor: '#f5f5f5' }}>
                    <TableCell><strong>Sr. No</strong></TableCell>
                    <TableCell><strong>Subject</strong></TableCell>
                    <TableCell><strong>Sender</strong></TableCell>
                    <TableCell><strong>Received Time</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedData.map((row, index) => (
                    <TableRow key={row.id} onClick={() => handleClickRow(row)} style={{ cursor: 'pointer' }}>
                      <TableCell>{startIndex + index + 1}</TableCell>
                      <TableCell>{row.subject}</TableCell>
                      <TableCell>{row.sender}</TableCell>
                      <TableCell>{row.receivedTime}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              count={Math.ceil(data.length / itemsPerPage)}
              page={page}
              onChange={handlePageChange}
              style={{ marginTop: '20px', display: 'flex', justifyContent: 'center' }}
            />
          </>
        ) : (
          <Paper className={classes.detailContainer}>
            <Typography variant="h6" className={classes.emailHeader} gutterBottom>
              {selectedRow.subject}
            </Typography>
            <Grid container spacing={2} className={classes.emailDetails}>
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1">
                  <strong>Sender:</strong> {selectedRow.sender}<br />
                  <strong>Recipient:</strong> {selectedRow.recipient}<br />
                  <strong>Received Time:</strong> {selectedRow.receivedTime}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1">
                  <strong>Content:</strong><br />
                  {selectedRow.content}
                </Typography>
              </Grid>
            </Grid>
            <div className={classes.backToTable} onClick={handleBackToTable}>
              <ArrowBack style={{ marginRight: '5px' }} />
              <span>Go back</span>
            </div>
          </Paper>
        )}
      </Container>
    </div>
  );
};

export default Mail;
